import * as React from 'react'
import Layout from '../components/Layout/Layout'

const contactThankYouPage = () => {
	return (
		<Layout>
			<p className={`bg-boswell-handwriting min-h-screen-50 pt-12 text-center text-lg`}>Thank you for your enquiry. A member of the Boswell Book Festival Team will be in touch soon.</p>
		</Layout>
	)
}

export default contactThankYouPage
